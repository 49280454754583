body.subcategories{

	#list{
		margin-top: 40px;
		@include media-breakpoint-down(sm) {
			margin-top: 0px;
		}
		.cards{
			margin-top: 60px;
			margin-bottom: 60px;
			@include media-breakpoint-down(md) {
				margin-top: 30px;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 0;
			}
			
			.item{
				cursor: pointer;
				height:300px;
				position: relative;
				margin-bottom: 15px;
				@include transition(all 0.4s ease);
				transition-delay: 0.2s;
				@include media-breakpoint-down(md) {
					height: 220px!important;
					width: 48%;
					top:10px;
					// margin-top:10px!important;
				}
				.cont-info{
					height:100%;
				}
				.con-info{
					@include media-breakpoint-down(md) {
						height: 220px!important;
					}
				}
				.imagen{
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					// height: 375px;
					height:300px;
					border-radius: 10px;
					@include transition(all 0.5s ease);
					@include media-breakpoint-down(md) {
						height: 220px;
					}
				}
				.capa{
					position: absolute;
					left:.25rem;
					bottom: 0;
					width: calc(100% - 0.5rem);
					height: 80px;
					@include transition(height 0.5s ease);
					border-bottom-left-radius:10px;
					border-bottom-right-radius:10px;
					border-top-left-radius:0px;
					border-top-right-radius:0px;
					@include media-breakpoint-down(md) {
						// bottom: -30px;
					}
					.icono-check{
						overflow: hidden;
						height: 0px;
						@include transition(height 0.4s ease);
						#svg-check{
							width: 35px;
							height: 35px;
							circle{
								stroke-dasharray: 185;
								stroke-dashoffset: 185;
								@include transition(all 0.4s ease-out);
							}
							polyline{
								stroke-dasharray: 70;
								stroke-dashoffset: 70;
								@include transition(all 0.4s ease-out);
							}
						}
					}
					img{
						position: relative;
						top: 10px;
						@include media-breakpoint-down(xs) {
							height: 47px!important;
							position: absolute;
							top: 20px
						}
						
					}
				}
				&:hover{
					// filter: brightness(70%);
					.capa{
						height: 100%;
						border-radius:10px;
						border-top-left-radius:10px;
						border-top-right-radius:10px;
						@include transition(all 0.4s ease);
						img{
							position: absolute;
							top: 20px
						}
					}
					.text-hover{
						display:block;
						opacity:1;
					}
				}
				&.js-seleccionada{
					// filter: brightness(33%);
					&:hover{
						// filter: brightness(33%);
					}
					.capa{
						height: 100%;
						position:absolute;
						top:0px;
						border-radius: 10px;
						img{
							position: absolute;
							top: 20px
						}
						.icono-check{
							height:35px;
							bottom: 20px;
							position: relative;
							#svg-check{
								circle{
									transition-delay: 0.3s;
									stroke-dashoffset: 0;
								}
								polyline{
									transition-delay: 0.5s;
									stroke-dashoffset: 0;
								}
							}
						}
					}
					.imagen{
						filter: brightness(53%);
					}
					.text-hover{
						display:block;
						opacity:1;
					}
				}
				&.js-redinactiva{
					transform: scale(0);
					width: 0;
					padding: 0!important;
				}
				.text-hover{
					position: absolute;
					top: 80px;
					z-index:2;
					color:white;
					opacity:0;
					padding-left:15px;
					padding-right:15px;
					@include transition(all 1s ease);
					@include fontSize(14px);
					line-height:1.3em;
				}
			}
		}

		&.filters-fixed{
			.filters{
				position: fixed;
				top: 50px;
				padding-top: 10px;
			}
			.cards{
				margin-top: 150px;
			}
		}
	}
}