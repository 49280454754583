body.offers{
	#header-image{
		padding: 20vh 0 10vh 0;
		background-color: #e8e8e8;
		background-size: cover;
		background-position: 50%;
		//Para testar
		//background-image: url('https://admin.rewardsforallcredits.com/images/offercategories/banner1645638671.png');
		@include media-breakpoint-down(md) {
			padding-top: 120px;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 0;
		}
		.cont-title {
			background-color: hsla(0,0%,100%,.4);
			padding: 24px 0 20px;
			min-height: 116px;
			@include media-breakpoint-down(md) {
				padding: 15px 0 10px;
				min-height: 45px;
			}
			h1{
				@extend .fuente-title;
				@include fontSize(60px);
				margin-bottom: 0;
				position: relative;
				padding-left: 10px;
				@include media-breakpoint-down(md) {
					@include fontSize(30px);
				}
			}
		}
	}
	#list{
		margin-top: -150px;
		.filters{
			border-bottom: none;
			display: block;
			background-color: #fff;
			margin-top: 65px;
			border-radius: 35px;
			box-shadow: 0 0 8px 2px rgba(0,0,0,.2);
			.filter-search{
				padding-bottom: 22px;
				padding-top: 22px;

				.cont-select{
					position:relative;
					width:100%;
					select{
						width:100%;
						-webkit-appearance: none !important;
						-moz-appearance: none;
						-o-appearance: none;
						-ms-appearance: none;
						appearance: none;
						background-color: transparent;
						border: 1px solid gray;
						padding: 10px 50px 10px 15px;
						@include border-radius(50px);
						@include media-breakpoint-down(sm) {
							margin-bottom: 8px;
							@include fontSize(13px);

						}
					}
					&:after{
						content:"";
						background-image: url("/images/ico-flecha-select.svg");
						width: 16px;
						height: 9px;
						background-size: cover;
						display: inline-flex;
						position: absolute;
						right: 15px;
						transform: translateY(-50%);
						top: 50%;
					}
				}
			}
			.btn-terciary{
				height: 100%;
				width: 50%;
				background-color:$rosa;
				align-items: center !important;
				display: flex !important;
				justify-content: center !important;
				text-transform: uppercase;
				@extend .fuente-book;
				color:$blanco;
			}
		}
		.cards{
			max-width: 1200px!important;
			.card-list{
				.card-single{
					// width: 48%!important;
					margin-top: 60px;
					@include media-breakpoint-down(sm) {
						margin-top: 30px;
					}
					.item{
						padding: 30px 30px 0 30px;
						-webkit-box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.1);
						-moz-box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.1);
						box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.1);
						border-bottom-left-radius:10px;
						border-bottom-right-radius:10px;
						height: 100%;
						@include media-breakpoint-down(md) {
							margin-bottom: 60px;
							line-height: 1.2em;
						}
						.bor-bot{
							border-bottom: 2px solid #000;
							@include media-breakpoint-down(md) {
								padding-bottom: 6px;
							}
						}
						.venue-name{
							@include fontSize(25px);
							@extend .fuente-title;
							position: relative;
							// &:before {
							// 	content: " ";
							// 	background-image: url(../images/stripes.svg);
							// 	background-repeat: no-repeat;
							// 	width: 20px;
							// 	height: 25px;
							// 	position: absolute;
							// 	top: 1px;
							// 	left: 0;
							// 	background-size: contain;
							// }
							@include media-breakpoint-down(md) {
								@include fontSize(20px);
								// &:before {
								// 	height: 20px;
								// }
							}
						}
						.venue-name2{
							@include fontSize(17px);
							@extend .fuente-title;

						}
						.offerterm1{
							@extend .fuente-title;
							@include fontSize(18px);
							color:$negro;
						}
						.cont-credits{
							@extend .fuente-title;
							color:$blanco;
							text-align: center;
							padding:7px 15px;
							background-color: $azul;
							@include border-radius(40px);
						}
						hr{
							margin:20px 0;
							border-bottom: 2px solid $azul;
							opacity: 1;
						}
						.row-gray{
							background-color: #F8F8F8;
							width: calc(100% + 60px);
							max-width: calc(100% + 60px);
							margin-left: -30px;
							padding: 15px 0
						}
						.btn{
							@extend .fuente-title;
							@include fontSize(16px);
							background-color:$rosa;
							padding:10px 20px;
							border-radius: 0;
							border-bottom-left-radius:10px;
							border-bottom-right-radius:10px;
							margin-bottom: 0px;
							color:$blanco;
							margin-left: -30px;
							width: calc(100% + 60px);
							max-width: calc(100% + 60px);
							&:after{
								content:"";
								background-size: cover;
								background-image: url('../images/ico-flecha-seleccionar.svg');
								width:13px;
								height:12px;
								display: block;
								position: relative;
								margin-left:10px;
								margin-top: 6px;
							}
						}
						label{
							color: $azul;
							padding: 3px 15px;
							border: 2px solid $azul;
							@extend .fuente-title;
							@include fontSize(10px);
							@include media-breakpoint-down(md) {
								padding: 3px 10px;
							}
							img{
								margin-left: 6px;
							}
							&.label-out {
								color: $azul;
								border: 2px solid $azul;
							}
							&.label-in {
								color: $rosa;
								border: 2px solid $rosa;
							}
						}
					}
				}
			}
		}
	}


	&.mini{
		#header-image {
			position: fixed;
			width: 100%;
			top: 35px;
			z-index: 5;
			padding: 40px 0 0 0;
			@include media-breakpoint-down(sm) {
				top: 10px;
			}
			.cont-title {
				min-height: 75px;
				padding-left: 20px;
				h1{
					@include fontSize(26px);
					&:before {
						width: 60px;
						height: 26px;
						top: 2px;
					}
				}
			}
		}
		#return{
			position: fixed;
			top: 95px;
			left: 20px;
			z-index: 10;
		}
		#list{
			margin-top: 270px;
			@include media-breakpoint-down(md) {
				margin-top: 250px;
			}
			.filters{
				position: fixed;
				top: 70px;
				height: 100px;
				z-index:9;
				@include media-breakpoint-down(md) {
					top: 48px;
				}
				@include media-breakpoint-down(sm) {
					top: 5px;
					height: 150px;
				}
				.filter-search{
					@include media-breakpoint-down(sm) {
						padding-top: 0px;
					}
				}
			}
		}
	}

	.show-while-loading{
		width: 100%;
		height: 100%;
		background-color: transparent;
		left: 0px;
		animation-duration: 1.8s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		padding-bottom: 30px;
		.loading-tit{
			background-color: #d2d2d2;
			width: 70%;
			height: 20px;
			margin-left: 15px;
			margin-top: 30px;
			opacity:0.5;
		}
		.loading-credits{
			background-color: #d2d2d2;
			width: 20%;
			height: 40px;
			margin-left: 15px;
			margin-top: 30px;
			@include border-radius(50px);
			opacity:0.5;
		}
		.loading-border{
			background-color: #d2d2d2;
			width: 95%;
			height: 2px;
			margin-left: 15px;
			margin-top: 30px;
			opacity:0.5;
		}
		.loading-txt{
			background-color: #d2d2d2;
			width: 95%;
			height: 50px;
			margin-left: 15px;
			margin-top: 15px;
			opacity:0.5;
		}
		.loading-dire{
			background-color: #d2d2d2;
			width: calc(100% - 10px);
			height: 50px;
			margin-left: 0px;
			margin-top: 15px;
			opacity:0.5;
		}
		.loading-img{
			background-color: #d2d2d2;
			width: 100%;
			height: 70px;
			margin-left: 15px;
			margin-top: 15px;
			opacity:0.5;
		}
	}
	.hide-while-loading{
		display: none!important;
	}
}