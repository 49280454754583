body{
	&.login-registro{
		background-image: unset;
		background-color: $beige-claro;
		.cont-images{
			.img-right{
				position:absolute;
				right:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			.img-top{
				position:absolute;
				width:100%;
				top:30px;
				left:0px;
				height:auto;
				@include media-breakpoint-down(xl) {
					max-width: 900px;
					left:unset;
					right:0px;
				}
			}
			.img-left{
				position:absolute;
				left:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			
		}
		#section-home{
			padding-bottom: 100px;
		}
		// .cont-texto{
		// 	margin-top: 10vw;
		// 	@include media-breakpoint-down(lg) {
		// 		margin-top: 55vw;
		// 	}
		// 	@include media-breakpoint-down(md) {
		// 		margin-top: 63vw;
		// 	}
		// 	@include media-breakpoint-down(sm) {
		// 		margin-top: 75vw;
		// 	}
		// 	.img-bottom{
		// 		margin-top: 10vw;
		// 	}
		// }
	}
}
#section-home{
	flex: 1 1 auto;
	// min-height: 275px;
	position:relative;
	// padding-bottom: 10vh;
  	padding-top: 10vh;
	
	.cont-ventana{
		background-color: $verde;
		border-radius:0px;
		padding:25px;
		position:relative;
		@include media-breakpoint-down(xl) {
			margin-top: 20vw;
		}
		@include media-breakpoint-down(md) {
			margin-top: 2	5vw;
		}
		@include media-breakpoint-down(sm) {
			padding: 20px 10px 20px;
		}
		@media (max-height: 700px) {
			padding:15px;
		}
		.campo{
			@include border-radius(4px!important);
		}
		input, select{
			color:$verde;
		}
		&.cont-recordar, &.cont-reset, &.cont-reset-fin, &.cont-bienvenido, &.cont-activacion, &.cont-validar, &.cont-validado{
			h1{
				@include fontSize(20px);
				font-weight:500;
				border-bottom:1px solid $rojo;
				padding-bottom:10px;
				color:$rojo;
			}
			.subtitulo{
				margin-top:15px;
				margin-bottom:10px;
				line-height: 1.2em;
				color:white;
				text-align: center;
			}
			label{
				font-family: "Work Sans", sans-serif;
				font-weight:500;
			}
			p{
				color:white;
			}
			a{
				color:white;
			}
			.mini{
				@include fontSize(12px);
				line-height:1.2em;
				color:$blanco;
			}
			input{
				@include border-radius(4px!important);
			}
			.has-error{
				input{
					&.form-control{
						// color:#D82240;
					}
				}
			}
			// a.btn-primary{
			// 	line-height:2.4em;
			// 	@media (max-height: 900px) {
			// 		line-height:3em;
			// 	}
			// 	@media (max-height: 700px) {
			// 		line-height:2.1em;
			// 	}
			// }
		}
		&.cont-validar{

			label{
				@include fontSize(12px);
				font-family: "Work Sans", sans-serif;
				font-weight:400;				width:100%;
				line-height:1.2em;
				margin-top:20px;
			}
			.h1{
				@include fontSize(20px);
				font-family: 'Gotham Bold';
				border-bottom:1px solid $blanco;
				padding-bottom:10px;
			}
			input{
				margin-top:40px;
			}
			p{
				color:$blanco;
				margin-top:15px;
			}
		}
		&.cont-reset{
			p{
				margin-top: 15px;
				margin-bottom: 20px;
			}
			.ico-mostrar{
				position: absolute;
				right: 30px !important;
				top: 45px !important;
			}
		}
		&.cont-reset-fin{
			h1{
				@include fontSize(22px);
				color:$rojo;
				border-bottom:none;
				margin-bottom:0px;
				padding-bottom:0px;
			}
			p{
				margin-top:30px;
			}
			a{
				//line-height:2.25em; //Comentado por Rafa porque parece que no son necesarias
			}
		}
		&.cont-bienvenido{
			p{
				margin-top:15px;
			}
			a{
				//line-height:2.25em; //Comentado por Rafa porque parece que no son necesarias
			}
		}
		&.cont-activacion{
			p{
				margin-top:15px;
			}
			a{
				//line-height:2.25em; //Comentado por Rafa porque parece que no son necesarias
			}
		}
		::-webkit-input-placeholder { /* Edge */
			color: $verde;
		}
		
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: $verde;
		}
		
		::placeholder {
			color: $verde;
		}
		.nav-tabs{
			border-bottom:none;
			.nav-item{
				width:50%;
				border-bottom:1px solid #a98f72;
				.nav-link{
					border-radius: 0px;
					border:none;
					background-color: transparent;
					color: #a98f72;
					@include fontSize(20px);
					font-family: "Work Sans", sans-serif;
					font-weight:400;
					&.active{
						border-bottom: 4px solid $rojo;
						color:$rojo;
					}
				}
			}
		}
		.tab-1{
			padding-top:40px;
			@include media-breakpoint-down(xs) {
				padding: 25px 15px 15px 15px;
			}
			@media (max-height: 700px) {
				padding-top:20px;
			}
			// display: block !important;
			label{
				font-family: "Work Sans", sans-serif;
				color: $blanco;
			}
			p{
				@include fontSize(16px);
				font-family: "Work Sans", sans-serif;
				font-weight:400;
				color:white;
				margin-bottom:0px;
				margin-top: 20px;
			}
			.ico-mostrar{
				position: absolute;
				right: 15px!important;
				top: 15px!important;
			}
			.cont-links{
				margin-top:40px;
				@include fontSize(16px);
				color:$blanco;
				@media (max-height: 700px) {
					margin-top:20px;
				}
				a{
					color:$blanco;
					text-decoration:underline;
					margin-bottom: 10px;
				}
			}
			select{
				font-family: "Work Sans", sans-serif;
				font-weight:400;
				width: 100% !important;
				option{
					font-family: 'Gotham Book'!important;
				}
			}
			.cont-checkbox{
				label{
					@include fontSize(16px);
					font-family: "Work Sans", sans-serif;
					font-weight:400;
					color:white;
					a{
						color:$blanco;
						text-decoration:underline;
					}
				}
				.css-checkbox{
					width: 15px !important;
					height: 15px !important;
					margin-right:10px;
				}
			}
		}
	}
	.tab-2{
		@include media-breakpoint-down(xs) {
			padding: 25px 0px 15px 0px;
		}
		label{
			font-family: "Work Sans", sans-serif;
			font-weight: 500;
			color:$blanco;
			padding-left: 0;
			margin-bottom: 5px;
		}
		.ico-mostrar{
			right: 25px;
			top: 10px;
			position:absolute;
		}
		.titulo-legal{
			@include fontSize(15px);
			color:$blanco;
			line-height: 12px;
			&.mini{
				@include fontSize(12px);
			}
		}
		.campo{
			text-align: center!important;
		}
		hr{
			&.separador{
				border-top:1px solid $rojo;
				opacity:1;
				margin-bottom:0px;
			}
		}
		.txt-options{
			color:white;
			@include fontSize(18px);
			text-align: center;
			font-family: "Work Sans", sans-serif;
			font-weight:400;
		}
		.cuadro-txt-legal{
			h3{
				margin-top:20px;
				margin-bottom:15px;
				@include fontSize(16px);
				@extend .fuente-book;
			}
			p, a{
				@include fontSize(10px);
				line-height:1.4em;
			}
			a{
				text-decoration:underline;
				color:$blanco;
			}
			.mas-info{
			}
		}
		select{
			font-family: "Work Sans", sans-serif;
			font-weight:400;
			width: 100% !important;
			text-align: center;
			option{
				font-family: "Work Sans", sans-serif!important;
				font-weight:400;
			}
		}
		.cont-checkbox{
			label{
				@include fontSize(15px);
				line-height:1.4em;
				color:$blanco;
				font-family: "Work Sans", sans-serif;
				font-weight:400;				
				padding-left: 28px;
				a{
					color:$blanco;
					text-decoration:underline;
					font-family: "Work Sans", sans-serif;
					font-weight:400;
				}
			}
			.css-checkbox{
				width:unset !important;
				height:unset !important;
				margin-right:10px;
			}
		}
	}
}

.age-filter{
	h1{
		font-family: "Work Sans", sans-serif;
		font-optical-sizing: auto;
		font-weight: 600;
		font-style: normal;
		color: #da291c;
		@include media-breakpoint-down(md) {
			font-size: 22px!important;
		}
	}
	h4{
		@include media-breakpoint-down(md) {
			font-size: 16px!important;
		}
	}
	p{
		@include media-breakpoint-down(md) {
			font-size: 14px!important;
		}
	}
}

#networks_logos{
	flex: 0 1 auto;
	padding-top: 12px;
	padding-bottom: 12px;
	.network{
		height: 60px;
		width: auto;
		margin: 0 8px;
	}
	.btn-transparent{
		margin-bottom:30px;
	}
}

body{
	&.coming-soon{
		background-image: unset;
		background-color: $beige-claro;
		.cont-images{
			.img-right{
				position:absolute;
				right:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			.img-top{
				position:absolute;
				width:100%;
				top:30px;
				left:0px;
				height:auto;
				@include media-breakpoint-down(md) {
					top:30px;
				}
			}
			.img-left{
				position:absolute;
				left:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			
		}
		
		.cont-texto{
			margin-top: 10vw;
			@include media-breakpoint-down(lg) {
				margin-top: 50vw;
			}
			@include media-breakpoint-down(md) {
				margin-top: 45vw;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 40vw;
			}
			.img-bottom{
				margin-top: 10vw;
			}
			h1{
				font-family: "Work Sans", sans-serif;
				font-optical-sizing: auto;
				font-weight: 600;
				font-style: normal;
				// @include fontSize(58px);
				color:$rojo;
				
			}
			h2{
				font-family: "Work Sans", sans-serif;
				font-optical-sizing: auto;
				font-weight: 500;
				font-style: normal;
				color:$beige-oscuro;
			}
			p{
				font-family: "Work Sans", sans-serif;
				font-optical-sizing: auto;
				font-weight: 400;
				font-style: normal;
				color:$verde;
				letter-spacing: unset;
			}

			h3{
				font-family: "Work Sans", sans-serif;
				font-optical-sizing: auto;
				font-weight: 500;
				font-style: normal;
				color:$beige-oscuro;
			}
			br{
				@include media-breakpoint-down(sm) {
					display:none;
				}
			}
		}
	}
}

body{
	&.previa{
		background-image: unset;
		background-color:$beige-claro;
		#section-home{
			padding-top: 40px;
			@include media-breakpoint-down(md) {
				padding-top: 60px;
				padding-bottom: 60px;
			}
			@include media-breakpoint-down(sm) {
				padding-top: 30px;
			}
			
			.logo-spanish{
				max-width: 350px;
				margin-right: unset;
				margin-left: unset;
				display: block;
				@include media-breakpoint-down(xxl) {
					max-width: 250px;
				}
				@include media-breakpoint-down(xl) {
					max-width: 200px;
    				margin-top: 10px !important;
				}
				
			}
			
			
			.cont-texto{
				h1{
					font-family: "Work Sans", sans-serif;
					font-optical-sizing: auto;
					font-weight: 600;
					font-style: normal;
					color:$rojo;
					text-align: left;
					@include media-breakpoint-down(lg) {
						font-size: 25px;
						  margin-top: 10px !important;
					}
				}
				ul{
					padding-left:0px;
					list-style:none;
					color:$verde;
					li{
						font-family: "Work Sans", sans-serif;
						font-optical-sizing: auto;
						font-weight: 600;
						font-style: normal;
						span{
							color:white;
							width:30px;
							height:30px;
							@include border-radius(100%);
							background-color: $verde;
							float:left;
							margin-right: 10px;
						}
						small{
							font-weight:400;
							@include fontSize(12px);
						}
						p{
							text-indent: 0px;
  							margin-left: 40px;
							  @include media-breakpoint-down(lg) {
								font-size: 16px;
							}
						}
					}
				}
				.cont-sello{
					width:45vw;
					position:relative;
					@include media-breakpoint-down(md) {
						width: 90vw;
						position: relative;
						text-align: right;
						margin-top: -15px;
					}
					.sello{
						@include media-breakpoint-down(md) {
							position: relative;
							bottom:0px;
							width: 30vw;
							right: 0px;
							transform: rotate(13deg);
						}
					}
				}
				.cont-top{
					@include media-breakpoint-down(md) {
						margin-top: 50px;
					}
				}
				.cont-fondo{
					right: -12vw;
					position: relative;
					margin-top: 0px!important;
					@include media-breakpoint-down(md) {
						position: relative	;
						right: 0;
						img{
							width: calc(100vw + 2px);
							max-width:calc(100vw + 20px);
							margin-left: -15px;
						}
					}
				}
				
			}
			.cont-image{
				background-image: url('/images/imagen-home.png');
				background-size: cover;
				position: relative;
				.sello{
					max-width: 300px;
					position: absolute;
					bottom: 90px;

					@include media-breakpoint-down(lg) {
						max-width: 250px;
					}
				}
			}
		}
	}
}

// body{
// 	&.remember-confirm{
// 		.cont-images{
// 			// top: -185px;
// 			position: relative;
// 			z-index: -1;
// 			top:unset;
// 			@include media-breakpoint-down(md) {
// 				// top: -175px;
// 				right: -23px;
// 			}
// 			.img-right{
// 				position:absolute;
// 				right:0px;
// 				top:30px;
// 				width:33%;
// 				height:auto;
// 			}
// 			.img-top{
// 				position:absolute;
// 				width:100%;
// 				top:30px;
// 				left:0px;
// 				height:auto;
// 				@include media-breakpoint-down(md) {
// 					top:30px;
// 				}
// 			}
// 			.img-left{
// 				position:absolute;
// 				left:0px;
// 				top:30px;
// 				width:33%;
// 				height:auto;
// 			}
			
// 		}
// 	}
// }
body{
	background-color: $beige-claro;
	.container{
		max-width:1600px;
		@include media-breakpoint-down(xl) {
			max-width:100%;
		}
	}
	&.faqs, &.legales{
		.section-faqs{
			margin-top:150px;
			padding-bottom:50px;
			position: relative;
			@media only screen and (max-width: 1500px) {
				margin-top:60px;
			}
			@include media-breakpoint-down(md) {
				margin-top: 75px;
  			}
			.sello-spanish{
				position: absolute;
				left:30px;
				top: -80px;
				max-width: 300px;
				
				@media only screen and (max-width: 1500px) {
					position:relative;
					transform: translate(-50%);
					left: 50%;
					max-width: 250px;
					top: 0px;
					margin-bottom: 20px;
				}
				@include media-breakpoint-down(md) {
					max-width: 200px;
				}
			}
		}
		h1{
			font-family: "Work Sans", sans-serif;
			font-optical-sizing: auto;
			font-weight: 600;
			font-style: normal;
			color:$rojo;
			@include media-breakpoint-down(md) {
				font-size: 22px!important;
			}
		}
		p{
			font-family: "Work Sans", sans-serif;
			font-optical-sizing: auto;
			font-weight: 400;
			font-style: normal;
			color:$verde;
			letter-spacing: unset;
		}

		h3{
			font-family: "Work Sans", sans-serif;
			font-optical-sizing: auto;
			font-weight: 500;
			font-style: normal;
			color:$beige-oscuro;
		}
		button{
			color:$verde!important;
			font-family: "Work Sans", sans-serif;
			font-optical-sizing: auto;
			font-weight: 500;
			font-style: normal;
			
		}
		
		.accordion{
			.accordion-item{
				background-color: transparent;
				// border-top:1px solid $verde;
				// border-bottom:1px solid $verde;
				border-radius: 0px;
				.accordion-button{
					background-color:transparent;
					border-top: 1px solid #13322b;
					border-radius: 0px;
					
					p{
						@include fontSize(24px);
						font-weight: 600;
						margin-bottom: 0px;
						margin-left:15px;
					}
					&:after{
						display:none;
					}
					&:focus{
						box-shadow:none;
					}
					&:before{
						transform: rotate(90deg);
						background-image:url('/images/ico-faq.svg');
						width:26px;
						height:26px;
						content: "";
						background-size: cover;

						transition: ease all 0.3s;
						position: absolute;
  						left: 0px;
					}
					&.collapsed{
						&:before{
							background-image:url('/images/ico-faq.svg');
							width:26px;
							height:26px;
							content: "";
							background-size: cover;
							transform: rotate(0deg);
							position: absolute;
  							left: 0px;
							transition: ease all 0.3s;
						}
					}
					
				}
				&:last-child{
					.accordion-button{
						border-bottom:none;
						&.collapsed{
							border-bottom:1px solid $verde;
						}
					}
					.accordion-body{
						border-bottom:1px solid $verde;
					}
				}
				.accordion-body{
					padding-top: 0px;
					p{
						padding-left: 15px;
						color:$verde;
					}
					// br{
					// 	margin-bottom:10px;
					// }
					a{
						color:$verde;
						text-decoration:underline;
					}
				}
			}
			
		}
		.border-top{
			border-top:1px solid #837f78!important;
		}
		.border-bottom{
			border-bottom:1px solid #837f78!important;

		}
	}
	&.legales{
		margin-top:100px;
	}
}

