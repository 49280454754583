@import "common/variables";

@import "~bootstrap/scss/bootstrap";


@import "common/fonts";
@import "common/mixins";

@import "common/elementos";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/home";
@import "layouts/categories";
@import "layouts/subcategories";
@import "layouts/offers";
@import "layouts/contact";
@import "layouts/private-area";
@import "layouts/impressum";
@import "layouts/ende";