body.home.home-area{
	background-color: $beige-claro;
	background-image: none !important;

	//se puede poner en global?!
	.cont-images{
		top: -185px;
  		position: relative;
		z-index: -1;
		@include media-breakpoint-down(md) {
			top: -175px;
			right: 0;//JC
		}
		.img-right{
			position:absolute;
			right:0px;
			top:30px;
			width:33%;
			height:auto;
		}
		.img-top{
			position:absolute;
			width:100%;
			top:30px;
			left:0px;
			height:auto;
			@include media-breakpoint-down(md) {
				top:30px;
			}
		}
		.img-left{
			position:absolute;
			left:0px;
			top:30px;
			width:33%;
			height:auto;
		}
		
	}
	.img-bottom{
		max-width: 200px;
		margin-right: auto;
		margin-left: auto;
	}
	#sectionuser{
		margin-bottom: 170px;
		@include media-breakpoint-down(md) {
			margin-bottom: 50px;
		}

		.cont-user{
			padding: 20px 0px;
			@include media-breakpoint-down(sm) {
				padding-top:20px;
				margin-top:0px;
			}
			p, .total_creditos{
				@extend .fuente-book;
				@include fontSize(24px);
				line-height: 1.2em;
				color:$verde;
				margin: 0;
				padding: 0;
				font-weight:400;
				// @include media-breakpoint-down(md) {
				// 	@include fontSize(15px);
				// }
				// @include media-breakpoint-down(sm) {
				// 	@include fontSize(20px);
				// 	padding-bottom:10px;
				// }
				span{
					@extend .fuente-title;
				}
			}
			h3{
				@include fontSize(32px);
			}
		}
	}
	#section-canjeo{
		margin-top: 40px;
		.ventana{
			background-color:$verde;
			padding: 50px 15px;
			margin-bottom: 100px;
			h2{
				color:$rojo;
				@include fontSize(24px);
				@extend .fuente-medium;
			}
			p{
				color:white;
				@include media-breakpoint-down(md) {
					@include fontSize(15px);
				}
			}
		}
	}
	.cont-formulario{
		@include media-breakpoint-down(lg) {
			margin-top: 30vw;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 20vw;
		}
		@media only screen and (max-width: 575px) {
			margin-top: 22vw;
		}
		@media only screen and (max-width: 420px) {
			margin-top: 20vw;
		}
	}
	#section-area{
		// flex: 1 1 auto;
		margin-top: 190px;
		@include media-breakpoint-down(md) {
			margin-top: 170px;
		}
		h1{
			font-family: "Work Sans", sans-serif;
			font-optical-sizing: auto;
			font-weight: 600;
			font-style: normal;
			// @include fontSize(58px);
			color:$rojo;
			margin-bottom:20px;
		}
		
		hr{
			border: 2px solid #004ccf;
			opacity: 1;
			max-width: 250px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 0px
		}
		h2{
			@extend .fuente-medium;
			@include fontSize(20px);
			color:$azul;
			text-transform: uppercase;
		}
		h3{
			@extend .fuente-medium;
			@include fontSize(16px);
			color:$negro;
			text-transform: uppercase;
			text-align: left;
			margin:6vh 0px 4vh;
		}
		#formCodigo{
			@include media-breakpoint-down(sm) {
				margin-top:-40px;
			}
		}
		form{
			.form-group{
				margin-top: 0;
				margin-bottom: 20px;
				position:relative;
				
				input, select{
					&.form-control{
						@include border-radius(0px);
						background-color: white;
						border-radius: 10px;
						border:1px solid $verde;
						// border-bottom: 1px solid $gris!important;
						color:$verde;
						text-align: center;
					}
					&::-webkit-input-placeholder { /* Edge */
						color:$verde;
					  }
					  
					&:-ms-input-placeholder { /* Internet Explorer 10-11 */
						color:$verde;
					  }
					  
					&::placeholder {
						color:$verde;
					  }
					  &:focus{
						box-shadow:none;
					  }
				}
				.ico-editar{
					position: absolute;
					transform: translateY(-50%);
					top: 50%;
					margin-right: 15px;
					img{
						width: 20px;
						height: 20px;
					}
				}
				.ico-mostrar{
					position: absolute;
					right: 30px!important;
					top: 15px!important;
				}
				.cont-checkbox{
					label{
						@include fontSize(12px);
						line-height:1.4em;
						color:$blanco;
						a{
							color:$blanco;
							text-decoration:underline;
						}
					}
					.css-checkbox{
						width: 15px !important;
						height: 15px !important;
						margin-right:10px;
					}
				}
			}
		}
		.cont-historial{
			border-radius:4px;
			border:1px solid #C4C4C4;
			margin-bottom:10px;
			padding:10px;
			&.activo, &.inactivo{
				.codigo, .creditos{
					display: block;
					@include fontSize(12px);
					font-family: 'Gotham Medium';
					p{
						background-image: linear-gradient(60deg, #649FFF, #004ACF);
						-webkit-background-clip: text;
						color: transparent;  
						@include fontSize(12px);
						font-family: 'Gotham Medium';
						margin-bottom: 0;
					}
				}
				.creditos{
					border:2px solid;
					border-image-slice: 1;
					background-clip: padding-box, border-box;
					background-origin: padding-box, border-box;
					background-image: linear-gradient(#fff, #fff), linear-gradient(#649FFF, #004ACF);
					border-radius:100px;
					max-width: 120px;
					height: 30px;
					line-height: auto;
					margin-left: auto;
					margin-right: auto;
					pointer-events:none;
					text-decoration: none;
					p{
						line-height:26px;
					}
					@include media-breakpoint-down(xs) {
						margin-top:10px;
						margin-bottom:10px;
					}
				}
				.fecha{
					@include fontSize(12px);
					font-family: 'Gotham Book';
					color:#868686;
					margin-bottom: 0;
				}
			}
			&.inactivo{
				p{
					background-image:none;
					-webkit-background-clip: unset;
					color: #868686;  
				}
				.creditos{
					border:2px solid #EBEBEB;
					border-image-slice: unset;
					background-clip: unset;
					background-origin: unset;
					background-image: none;
					border-radius:100px;
					max-width: 120px;
					height: 30px;
					line-height: 2.2em;
					margin-left: auto;
					margin-right: auto;
					background-color:#EBEBEB;
					color:$blanco;
					p{
						background-image:none;
						-webkit-background-clip: unset;
						color: $blanco;  
					}
				}
			}
		}
	}
	#addcode{
		flex: 1 1 auto;

	}
	&.change-password{
		#sectionuser{
			margin-top:60px;
		}
		#section-password{
			flex: 1 1 auto;
			.ico-mostrar{
				position: absolute;
				right: 30px;
				top: 45px;
			}
		}
	}
	&.change-password-end{
		#sectionuser{
			margin-top:60px;
		}
		.section-password{
			h1{
				color:$rojo;
				@include fontSize(30px);
			}
		}
	}
	&.validate-code-private{
		.cont-images{
			// top: -185px;
			position: relative;
			z-index: -1;
			top:unset;
			@include media-breakpoint-down(md) {
				// top: -175px;
				right: -23px;
			}
			.img-right{
				position:absolute;
				right:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			.img-top{
				position:absolute;
				width:100%;
				top:30px;
				left:0px;
				height:auto;
				@include media-breakpoint-down(md) {
					top:30px;
				}
			}
			.img-left{
				position:absolute;
				left:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			
		}
		#sectionuser{
			margin-top:190px;
			margin-bottom: 0px !important;
			@include media-breakpoint-down(lg) {
				margin-top: 55vw;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 20vw;
			}
			@media only screen and (max-width: 575px) {
				margin-top: 62vw;
			}
			@media only screen and (max-width: 420px) {
				margin-top: 60vw;
			}
		}
		.ventana{
			background-color:$verde;
			padding: 50px 15px 0;
			h2{
				color:$rojo;
				@include fontSize(24px);
				@extend .fuente-medium;
			}
			p{
				color:white;
			}
			&.foto{
				padding:0 15px;
				#btn-examinar-photo1{
					width: 100%;
					margin-left: auto;
					margin-right: auto;
					background-color: white;
					color:$verde;
					font-weight:400px;
					font-family: "Work Sans", sans-serif;
					@include fontSize(16px);
					line-height: 1em;
				}
				#ficfoto1{
					position: absolute;
					transform: translateX(-50%);
					left: 50%;
				}
				p{
					color:white;
				}
			}
		}
		.section-area{
			flex: 1 1 auto;
			margin-top:0px!important;
			margin-bottom: 170px!important;
		}
	}
}

.section-campaigns-historial{
	flex: 1 1 auto;
	.card{
		color:black;
		border:none;
		@include border-radius(8px);
		box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.07);
		padding: 10px 0px 0px;
		.cont-title{
			padding:0px 20px 10px;
			position:relative;
			.title{
				@extend .fuente-medium;
				@include fontSize(24px);
				line-height: 1.2em;
				margin: 0;
				padding: 0;
			}
			hr{
				position: absolute;
				width: calc(100% - 40px);
				bottom: 0px;
				transform: translateX(-50%);
				left: 50%;
				opacity: 1;
				margin: 0px;
				border: 1px solid #004ccf;
			}
			.creditos{
				border: 2px solid;
  				border-image: linear-gradient(45deg, #649DFF, #004CCF) 1;
				@include border-radius(30px);
				padding: 4px 15px;
				border: double 2px transparent;
				background-image: linear-gradient(white, white), linear-gradient(45deg, #649DFF, #004CCF);
				background-origin: border-box;
				background-clip: padding-box, border-box;
				float: right;
				min-width: 125px;
				height: 30px;
				  p{
					@extend .fuente-medium;
					@include fontSize(12px);
					margin-bottom:0px;
					background: linear-gradient(45deg, #649DFF, #004CCF);
					-webkit-background-clip: text;
					color: transparent;
				  }
			}
		}
		.info{
			padding: 30px 20px 0px;
			margin-bottom:30px;
			.txtintro{
				@extend .fuente-book;
				@include fontSize(16px);
				line-height: 1.2em;
				margin: 0;
				padding: 0;
			}
		}
		.info-centro{
			.media{
				background-color:rgba(235, 235, 235, 0.5);
				padding: 15px;
				img{
					max-width:140px;
				}
				.centro, .direccion, .url, .telefono{
					@extend .fuente-title;
					@include fontSize(12px);
					color:$negro;
				}
				.direccion, .telefono{
					@extend .fuente-book;
				}
				.direccion{
					color:$azul-link;
					text-decoration:underline;
				}
			}
			.cont-info-campaign{
				background: rgb(100,157,255);
				background: -moz-linear-gradient(235deg, rgba(0,76,207,1) 0%, rgba(100,157,255,1) 100%);
				background: -webkit-linear-gradient(235deg, rgba(0,76,207,1) 0%, rgba(100,157,255,1) 100%);
				background: linear-gradient(235deg, rgba(0,76,207,1) 0%, rgba(100,157,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#649DFF",endColorstr="#649DFF",GradientType=1);
				padding:5px 20px;
				@include border-radius(0px 0px 8px 8px);
				p{
					@extend .fuente-body;
					@include fontSize(14px);
					color:$blanco;
					margin-bottom:0px;
				}
			}
		}
	}
}

.campaigns{
	#section-intro{
		padding: 4vh 0px 2vh;
		p{
			color:$negro!important;
			margin-bottom:0px;
			&.titulo{
				@include fontSize(20px);
			}
			&.texto{
				@include fontSize(14px);
				margin-top:30px;
			}
		}
		.subtitulo{
			@extend .fuente-medium;
			@include fontSize(24px);
			line-height: 1.2em;
			margin: 0;
			padding: 0;
		}
	}
	.section_campaigns{
		flex: 1 1 auto;
		.cont-card{
			.card{
				border:none;
				@include border-radius(8px);
				box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.07);
				padding:0px;
				pointer-events: all;
				cursor: pointer;
				padding-bottom:30px;
				position:relative;
				z-index:0;
				border:2px solid transparent;
				transition: all ease 0.5s	;
				.card-info{
					.title{
						@extend .fuente-medium;
						@include fontSize(24px);
						line-height: 1.2em;
						margin: 0;
						padding: 0;
						background-color: $azul-claro;
						text-align: center;
						@include border-radius(8px 8px 0px 0px);
						padding: 15px;
					}
					.info{
						padding:30px 20px;
						.txtintro, .txtlimit, .txtintro2{
							margin-bottom:10px;
						}
						.txtintro{
							@extend .fuente-medium;
							@include fontSize(20px);
							line-height: 1.2em;
						}
					}
				}
				.networks{
					padding-left: 20px;
					padding-right: 20px;
					.category{
						align-items: center !important;
						display: block;
						margin-right: 15px;
						float:left;
						img{
							height:40px;
							opacity:0.3;
						}
						.ico-desplegable{
							position:relative;
							img{
								width: 10px;
								height: 10px;
								opacity: 1;
							}
							.hover{
								position:absolute;
								background: #DEF2FF;
								top: 30px;
								transform: translateX(-50%);
								left: 50%;
								width: 300px;
								padding: 15px;
								@include fontSize(14px);
								color:$negro;
								z-index:1;
								&:before{
									bottom: 100%;
									left: 50%;
									border: solid transparent;
									content: "";
									height: 0;
									width: 0;
									position: absolute;
									pointer-events: none;
									border-color: rgba(222, 242, 255, 0);
									border-bottom-color: #DEF2FF;
									border-width: 8px;
									margin-left: -8px;
								}
							}
							&:hover{
								.hover{
									display:block!important;
								}
							}
						}
					}
				}
				&:hover, &.active{
					border:2px solid $rosa;
				}
				// &.active{
				// 	border:2px solid $rosa;
				// }
			}
		}
		.loadajax-item{
			background: transparent;
			.card{
				animation-duration: 1.8s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				animation-name: placeHolderShimmer;
				animation-timing-function: linear;
				background: #f6f7f8;
				background: linear-gradient(to right, #e8e8e8 8%, #dfdfdf 38%, #e8e8e8 54%);
				background-size: 1000px 640px;
			
				min-width: 100px;
				min-height: 60px;
				border: 3px solid white;
			
				&[data-loadajax='blue']{
					background: #eff9ff;
					background: linear-gradient(to right, #eff9ff 8%, #eff9ff 38%, #eff9ff 54%);
					border:none;
					animation-duration: 6s;
				}
				border:none;
				position:relative;
				z-index:99999;
			}
			
			@keyframes placeHolderShimmer{
				0%{
					background-position: -468px 0
				}
				100%{
					background-position: 468px 0
				}
			}
		}
	}
}
body{
	&.remember, &.remember-confirm, &.remember-end, &.remember-reset, &.validate-code, &.welcome{
		background-color: $beige-claro;
		input{
			color:$verde;
		}
		.cont-images{
			// top: -185px;
			position: relative;
			z-index: -1;
			top:unset;
			@include media-breakpoint-down(md) {
				// top: -175px;
				right: -23px;
			}
			.img-right{
				position:absolute;
				right:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			.img-top{
				position:absolute;
				width:100%;
				top:30px;
				left:0px;
				height:auto;
				@include media-breakpoint-down(md) {
					top:30px;
				}
			}
			.img-left{
				position:absolute;
				left:0px;
				top:30px;
				width:33%;
				height:auto;
			}
			
		}
		#section-home{
			// margin-top:190px;
			@include media-breakpoint-down(lg) {
				margin-top: 27vw;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 20vw;
			}
			@media only screen and (max-width: 575px) {
				margin-top: 22vw;
			}
			@media only screen and (max-width: 420px) {
				margin-top: 16vw;
			}
		}
	}
	&.remember-confirm{
		position:unset!important;
		#section-home{
			position: unset;
		}
		.cont-images{
			position: absolute;
			left: 0;
			width: 100%;
			top: 75px;
			@include media-breakpoint-down(sm) {
				top: 60px;
			}
		}
	}
	&.remember-reset{
		.ico-mostrar{
			position: absolute;
			top: 45px;
			right: 30px;
		}
		#section-home{
			.cont-ventana{
				@include media-breakpoint-down(md) {
					margin-top: -5vw;
				}
				@include media-breakpoint-down(sm) {
					margin-top: -55vw;
				}
			}
			@media only screen and (max-width: 575px) {
				margin-top: 75vw!important;
			}
		}
		.cont-images{
			top: -15px;
			@include media-breakpoint-down(xl) {
				top: 5px;
			}
			@include media-breakpoint-down(sm) {
				top: -10px;
			}
		}

	}
}
