header{
	position: fixed;
	width:100vw;
	z-index:10;
	top: 0;
    background-color: #13322b;
  	height: 120px;
    min-height: 120px;
	flex: 0 1 120px;
	@include media-breakpoint-down(sm) {
		height: 90px;
    	min-height: 90px;
	}
	.logo-header{
		@include media-breakpoint-down(sm) {
			max-width:120px;
		}
	}
	.link{
		color:white;
		text-decoration: none;
		font-family: "Work Sans", sans-serif;
		@include fontSize(18px);
		font-weight:500;
	}
	.menu{
		padding:0;
		text-align: center;
		li{
			margin-left: 10px;
			list-style:none;
			display: inline-block;
			@include fontSize(16px);
			a{
				color:$txt-grey-dark;
                text-decoration: none;
				&:hover{
					@extend .fuente-title;
					color:$negro;
					text-decoration: underline;
				}
			}
			&.active{
				@extend .fuente-title;
				a{

					text-decoration: none;
					color:$rosa;
				}
			}
		}
	}

	&.header-area{
		height:80px;
		background-color:$verde;
		z-index:11;
		height:120px;
		min-height: 120px;
		@include media-breakpoint-down(md) {
			height: 90px;
    		min-height: 90px
		}
		.container{
			max-width:1600px;
			@include media-breakpoint-down(xl) {
				max-width:100%;
			}
		}
		.cont-menu{
			position:relative;
			@include media-breakpoint-down(sm) {
				right:unset;
				left:0px;
				top:0px;
			}
			.menu{
				a{
					margin-right:13px;
					@include fontSize(20px);
					font-family: 'Gotham Medium';
					color:$azul;
					margin-left: 15px;
					text-decoration: none;
				}
			}
		}
		.logo{
			img{
				max-width:180px;
				@include media-breakpoint-down(lg) {
					max-width:130px;
				}
				@include media-breakpoint-down(sm) {
					max-width:120px;
				}
			}
			
		}
		.cont-user-menu{
			.btn-user{
				background-color:transparent;
				border:none;
				box-shadow:none;
				outline: none;
				// display:none!important;
				&:after{
					background-image:url("../images/ico-arrow-down-azul.svg");
					width:14px;
					height:8px;
					background-size: cover;
					border: none;
					display:none;
					@include media-breakpoint-down(md) {
						display:none!important;
					}
				}
				&:hover{
					background-color:transparent!important;
					outline: none;
				}
				&:focus{
					border:none;
					box-shadow:none;
					outline: none;
				}
				&:active{
					background-color:transparent!important;
				}
				img{
					padding:0px;
					width:32px;
				}
			}
			.dropdown-menu{
				padding: 5px 0px;
				border-radius:0px;
				position: relative;
				display:flex;
				background-color: transparent;
  				border: none;
				// border:1px solid #DEF2FF;
				// top: 45px!important;
				// transform: translateX(-50%)!important;
				// left: 100px!important;
				@include media-breakpoint-down(md) {
					display:none;
				}
				@include media-breakpoint-down(sm) {
					top: 50px !important;
					right: unset;
					left:-35px !important;
				}
				a{
					@include fontSize(20px);
					font-family: "Work Sans", sans-serif;
					font-weight: 500;
					color:$blanco;
					// border-bottom:1px solid #DEF2FF;
					padding:10px;
					// padding-bottom: 10px;
					// padding-top: 10px;
					text-align: center;
					&:last-child{
						border:none;
					}
					&:hover{
						background-color:transparent;
						opacity:0.75;
					}
					@include media-breakpoint-down(xl) {
						@include fontSize(18px);
					}
					@include media-breakpoint-down(lg) {
						margin-right:0px;
						@include fontSize(16px);
					}
				}
				@include media-breakpoint-down(sm) {
					left: 110px !important;
				}
				&.show{
					display:block!important;
					background-color: $verde;
					padding:0px;
					transform: unset!important;
					position: absolute;
					top: 50px !important;
  					left: -135px !important;
					a{
						border-bottom:1px solid $beige-oscuro;
						&:first-child{
							border-top:1px solid $beige-oscuro;
						}
					}
					
				}
			}
			@include media-breakpoint-down(sm) {
				margin-top:10px;
			}
		}

	}
	&.small{
		// height: 50px;
		// min-height: 50px;
		transition: all 0.5s ease-out;
	}
	
}

