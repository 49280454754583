footer{
	padding:unset;
	flex: 0 1 auto;
	margin: 0;
	background-color: $verde;
	position: fixed;
	bottom: 0;
	width: 100%;
	@include media-breakpoint-down(md) {
		position: relative;
	}
	.menu{
		margin-top: 20px;
		padding:0;
		text-align: center;
		position: relative;
		li{
			list-style: none;
			display: inline-block;
			@include fontSize(14px);
			@extend .fuente-book;
			@include media-breakpoint-down(md) {
				display:block;
			}
			a{
				text-transform: unset;
				color:$blanco;
				text-decoration: none;
				margin-right:15px;
				&:hover{
					color:$txt-grey;
					text-decoration: none;
				}
				@include media-breakpoint-down(md) {
					margin-right: 0px;
				}
			}
			// &:after{
			// 	content:"|";
			// 	margin:0 5px;
			// 	color: $blanco;
			// }
			&:last-child{
				margin-right:0px;
			}
			.btn-secondary.btn-xs{
				@media only screen and (max-width: 1270px) {
					position: relative;
					left: 0;
					top: 4px;
					padding: 6px 14px;
				}
			}			
		}
	}	
	.loadajax-item{
		height: 15px;
		min-height: unset;
		background-color: none;	
		background:none;
		&::after{
			content: ''!important;
		}
	}

	&.interior{
		background-color:$verde;
		padding:0px;
		margin-top:30px;
		.cont-logos{
			display:none!important;
		}
		.menu{
			li{
				a{
					color:$blanco;
				}
				&:after{
					color:$blanco;
				}
			}
		}
	}

}

#modal-error{
	h2{
		@include fontSize(24px);
		@extend .fuente-medium;
		color:$rojo;
		line-height: 1.2em;
		text-align: center!important;
		margin-left: auto;
		margin-right: auto;
	}
	p, a{
		@include fontSize(18px);
		@extend .fuente-book;
		line-height: 1.2em;
		color:$negro;
		text-align: center!important;
	}
	a{
		text-decoration:underline;
	}
	ul{
		padding-left:0px;
		list-style: none;
		li, a{
			@include fontSize(14px);
			@extend .fuente-body;
			line-height: 1.2em;
			color:$negro;
			text-align: center!important
		}
		li{
			margin-top:15px;
		}
	}
}

