body.ende {
  position: relative;
 
  #section-ende {
   flex: 1 1 auto;
   // min-height: 275px;
   position: relative;
   padding-bottom: 10vh;
   padding-top: 10vh;
 
   h1 {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    color: #da291c;
   }
 
   h3 {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    color: #a98f72;
   }
 
   p {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: #13322b;
    letter-spacing: unset;
   }
 
   a {
    color: #5b4e3e;
    text-decoration: underline;
   }
  }
 
  .cont-images {
   top: 85px !important;
   .img-right {
    position: absolute;
    right: 0px;
    top: 30px;
    width: 33%;
    height: auto;
   }
   .img-top {
    position: absolute;
    width: 100%;
    top: 30px;
    left: 0px;
    height: auto;
    @include media-breakpoint-down(md) {
     top: 30px;
    }
   }
   .img-left {
    position: absolute;
    left: 0px;
    top: 30px;
    width: 33%;
    height: auto;
   }
  }
 }
 