
.fuente-title {
	font-family: "Work Sans", sans-serif;
	font-weight:600;
}

.fuente-medium {
	font-family: "Work Sans", sans-serif;
	font-weight: 500;
}
.fuente-book {
	font-family: "Work Sans", sans-serif;
	font-weight:400;
}
.fuente-body {
	font-family: "Work Sans", sans-serif;
	font-weight: 300;
}

html{
	font-size:$size-rem;
    // height: 100%;
    body{
		height: 100vh;
		@extend .fuente-body;
		.wrap{
			padding-top: 80px;
			@include media-breakpoint-down(md){
				padding-top: 60px;
			}
		}
    }
}

// buttons
.btn-link {
	color: $txt-grey;
	text-decoration: underline;
}
.btn-primary {
	@extend .fuente-title;
	background-color:$rojo;
	border-radius:10px;
	color:$blanco;
	@include fontSize(18px);
	font-weight: 600;
	border: none;
	height: 45px;
	padding-left: 40px;
	padding-right: 40px;
	text-align: center;
	position: relative;
	@include transition(all 0.4s ease-out);
	display: inline-flex !important;
	justify-content: center !important;
	align-items: center !important;
	width:300px;
	max-width: 100%;
	&:hover {
		background-color: $rojo !important;
		color: $blanco !important;
		opacity: 0.65;
	}
	&.disabled {
		background-color: $blanco;
		color: $negro;
		opacity: 1;
		pointer-events: all;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
		}
	}
}
.btn-secondary {
	@extend .fuente-title;
	background-color:#dadada;
	border-radius:10px;
	color:$blanco;
	@include fontSize(18px);
	font-weight: 600;
	border: none;
	height: 45px;
	padding-left: 40px;
	padding-right: 40px;
	text-align: center;
	position: relative;
	@include transition(all 0.4s ease-out);
	display: inline-flex !important;
	justify-content: center !important;
	align-items: center !important;
	width:300px;
	max-width: 100%;
	&:hover {
		background-color: #dadada !important;
		color: $blanco !important;
		opacity: 0.65;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
			@include filter(invert(100%));
		}
	}

	&.btn-xl {
		width: 400px;
		max-width: 100%;
	}

	&.btn-xs {
		@include fontSize(12px);
		height: 27px;
		line-height: 23px;
		padding: 2px 20px;
		position: absolute;
		top: -2px;
		white-space: nowrap;
		// right: 0px;/*-140*/
	}
}
.btn-terciary {
	background-color:$rojo;
	align-items: center !important;
	display: flex !important;
	justify-content: center !important;
	text-transform: uppercase;
	@extend .fuente-book;
	color:$blanco;
	@include fontSize(13px);
	border: none;
	border-radius: 0;
	height: 26px;
	text-align: center;
	transition: color .3s;
	&.active,:hover {
		background-color: $gris-hover!important;
		color: $blanco!important;
	}

}
.btn-transparent{
	white-space: nowrap;
	line-height: 23px;
	max-width: 260px;
	width:100%;
	text-transform: unset;
	height: 40px;
	padding: 9px;
	border-radius: 0;
	background-color: transparent;
	border: 2px solid #fff;
	color:$blanco;
	@include fontSize(12px);
	color:$blanco;
	font-family: 'Gotham Book';
	justify-content: center !important;
	display: flex !important;
	align-items: center !important;
	transition: all ease 0.5s;
	&:hover{
		border: 2px solid #fff;
		background-color:rgba(255, 255, 255, 0.2);
	}
}

.btn-skew {
	transform: skewX(-15deg);
	background-color: $negro;
	border-radius: 0;
	margin-top: 30px;
	padding: 7px 90px;
	color: $blanco;
	@extend .fuente-title;
	@include media-breakpoint-down(md){
		margin-top: 0;
	}
	span {
		transform: skewX(15deg);
		display: block;
	}
	&:hover {
		background-color: $txt-grey;
		color: $blanco;
	}
	&.btn-skew-small {
		padding: 5px 40px;
		max-width: 250px;
	}
}
.btn-return-abs{
	img{
		width: 50px;
		height: 50px;
	}
}
.carrusel-partners .swiper-button-prev{
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E");
}
.carrusel-partners .swiper-button-next {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23fff'/%3E%3C/svg%3E");
}
.btn-negro{
	background-color: $negro;
	color:$blanco;
}
.btn-xl{
	width: 400px;
	max-width: 100%;
}

// forms
.alert {
	display: none;
	padding: 5px 15px;
	margin-top: 10px;
	border-radius: 0;
	.mensaje-alerta {
		font-weight: 700;
		color: #000;
		font-size: 1rem;
		display: inline-block;
		margin-top: 20px;
		ul {
			list-style: none;
			padding-left: 15px;
			margin-bottom: 10px;
			li {
				font-weight: 300;
				color: #000;
				font-size: 0.8rem;
				&:before {
					content: "\f057";
					font-family: FontAwesome;
					font-size: 0.8rem;
					color: red;
					margin-right: 5px;
				}
			}
		}
	}
	&.fade {
		opacity: 0;
		transition: opacity 0.2s linear;
		&.show {
			opacity: 1;
			display: block;
		}
	}
}
.alert-dismissible .close {
	padding: 5px 15px 4px;
}
// input.form-control {
// 	border: none;
// 	border-radius: 0;
// 	height: 50px;
// 	text-align: center;
// 	@include fontSize(18px);
// 	.has-error & {
// 		border: 1px solid $error;
// 	}
// }
// select.form-control {
// 	width: 100%;
// 	height: 38px;
// 	border: none;
// 	background-color: #ebebeb;
// 	border: 1px solid #ebebeb;
// 	border-radius: 0;
// 	text-align: left;
// 	font-family: "Gotham Light";
// 	option {
// 		font-family: "Gotham Light";
// 	}
// }


// SPINER
@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}


// INFO USER
#sectionuser{
	flex: 0 1 auto;
	.user-info{
		padding: 20px 10vw;
		border:2px solid $negro;
		margin-top:45px;
		margin-bottom:45px;
		@include media-breakpoint-down(md){
			margin-top:25px;
			margin-bottom:25px;
			padding: 14px;
			line-height: 1.2em;
		}
		h1{
			@include fontSize(22px);
			@extend .fuente-title;
			color:$negro;
		}
		p{
			@include fontSize(18px);
			@extend .fuente-body;
			color:$negro;
			margin-bottom: 0;
		}
	}
}

//FILTER
.filter-search{
	margin-top: 10px;
	.cont-address{
		.input-group{
			height: 45px;
			.input-group-prepend{
				position: relative;
				&:after{
					content:" ";
					border-right: 2px solid black;
					margin-top: 7px;
					position: absolute;
					right: 0;
					top:0;
					height: calc(100% - 14px);
				}
				.input-group-text{
					border: 2px solid black;
					border-right: none;
					border-radius: 0;
					background-color: $blanco;
					height: 100%;
					width: 45px;
					background-image: url(../images/menu.svg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: 55% 55%;
				}
			}
			input{
				border: 2px solid black;
				border-left: none;
				padding-left: 15px;
				width: calc(100% - 46px);
				@include fontSize(16px);
				box-shadow: none!important;
				outline: none;
				color: $negro;
				&:focus, &:active{
					box-shadow: none!important;
					outline: none;
				}
			}
		}
	}
	.cont-slider{
		.label-title{
			@include fontSize(16px);
			color:$negro;
			vertical-align: text-bottom;
		}
		.label-slider{
			@include fontSize(16px);
			color:$rosa;
			font-family: 'Gotham Bold';;
			float: right;
		}
		#slider{
			background-color: #bdbdbd;
			height: 4px;
			transform: translateY(3px);
			.ui-slider-handle{
				border-radius: 50%;
				width: 18px;
				height: 18px;
				background-color: black;
				border: none;
				top: -8px;
			}
			.slider-start{
				width: 0;
				background-color: $azul;
				height: 4px;
				margin-top: -1px;
				border-radius: 3px;
			}
		}
		&.disabled{
			opacity: 0.35;
			pointer-events: none;
		}
	}
	.txt_resultados{
		background-color: #ebebeb;
		padding: 8px;
		span{
			display: inline-flex;
			padding-left: 15px;
			color:$negro;
		}
	}
}

//BACK BUTTON
#return{
	padding: 20px;
	width: 100px;
	left: 0;
	margin: 0;
	z-index: 10;
	position: relative;
	a{
		background-color: $blanco;
		width: 38px;
		height: 38px;
		border-radius: 50%;
		display: block;
		.ico-return{
			width: 40px;
			height: 40px;
			margin-right: 2px;
		}
	}
	@include media-breakpoint-down(md){
		display: none;
	}
}

//LIST OF RESULTS
#list{
	flex: 1 1 auto;
	.filters{
		position: relative;
		background-color: $blanco;
		width: 100%;
		left: 0px;
		right: 0px;
		z-index: 3;
		box-shadow: 0 14px 14px -14px rgba(0, 0, 0, 0.3);
		.filter-type{
			z-index: 1;
			position: relative;
			margin-bottom:0px;
			padding: 0;
			@include media-breakpoint-down(md){
				padding: 0 0 20px 0;
			}
			li{
				display: inline;
				margin:0 5px;
				@include fontSize(18px);
				color:$txt-grey;
				position:relative;
				padding-bottom: 10px;
				&:hover .filter-inout, &:hover .filter-categories, &:hover .filter-subcategories, &:hover .filter-provinces{
					display: block;
				}

				.filter-item {
					pointer-events: all;
					cursor: pointer;
					border-radius: 50px;
					border: 1px solid #979797;
					padding: 10px;
					display: block !important;
					margin-right: 15px;
					span {
						padding: 0;
						color: #000;
						display: -ms-flexbox;
						display: flex;
						&:after {
							content: "";
							background-image: url(../images/ico-arrow-down-gris.svg);
							width: 14px;
							height: 8px;
							display: block;
							margin-top: 10px;
							margin-left: 10px;
						}
					  }
				}
			}
			.filter-inout, .filter-categories, .filter-subcategories, .filter-provinces {
				position: absolute;
				background: #ffffff;
				border: 1px solid $negro;
				padding: 10px;
				margin-top: 10px;
				display:none;
				
			}
			.filter-inout, .filter-categories, .filter-subcategories, .filter-provinces {
				&:after, &:before {
					bottom: 100%;
					left: 50%;
					border: solid transparent;
					content: "";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				}
			}
			
			.filter-inout, .filter-categories, .filter-subcategories, .filter-provinces {
				&:after {
					border-color: rgba(255, 255, 255, 0);
					border-bottom-color: #ffffff;
					border-width: 10px;
					margin-left: -10px;
				}
			}
			.filter-inout, .filter-categories, .filter-subcategories, .filter-provinces {
				&:before {
					border-color: rgba(0, 0, 0, 0);
					border-bottom-color: #000000;
					border-width: 11px;
					margin-left: -11px;
				}
			}
			.filter-subcategories{
				min-width: 350px;
				transform: translateX(-50%);
				left: 50%;
				margin-top: 25px;
				width: 100%;
			}
			.filter-provinces{
				transform: translateX(-50%);
				left: 50%;
				margin-top: 25px;
			}
			.filterinout, .filtercategories, .filtersubcategories, .filterprovinces{
				input[type=checkbox]{
					display: none!important;
				}
				label{
					align-items: center;
					display: flex;
					@extend .fuente-title;
					@include fontSize(16px);
					text-transform: uppercase;
					color:$negro;
					margin-top:5px;
				}
				&:first-child{
					label{
						margin-top:0px;
					}
				}
				input[type=checkbox] + label{
					cursor:pointer;
				}
				label:before{
					content:'';
					background-image:url("../images/ico-check-not.svg");
					background-size: cover;
					background-repeat: no-repeat;
					height: 20px;
					width: 20px;
					margin-right: 10px;
					padding:10px;
				}
				input[type="checkbox"]:checked + label:before{
					content: '';
					background-image:url("../images/ico-check-checked.svg");
				}
				input[type="checkbox"]:not(:checked) + label:before{
					content:'';
					background-image:url("../images/ico-check-not.svg");
					background-size: cover;
					background-repeat: no-repeat;
					height: 20px;
					width: 20px;
					margin-right: 10px;
				}
			}
		}
		.filter-search{
			padding-bottom: 30px;
			// box-shadow: 0 14px 14px -14px rgba(0,0,0,.3);
		}

	}
}

//AJAX LOADER
.loadajax-item{
	animation-duration: 1.8s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #e8e8e8 8%, #dfdfdf 38%, #e8e8e8 54%);
	background-size: 1000px 640px;

	min-width: 100px;
	min-height: 60px;
	border: 3px solid white;

	&[data-loadajax='blue']{
		background: #eff9ff;
		background: linear-gradient(to right, #eff9ff 8%, #eff9ff 38%, #eff9ff 54%);
		border:none;
		animation-duration: 6s;
	}
}

@keyframes placeHolderShimmer{
	0%{
		background-position: -468px 0
	}
	100%{
		background-position: 468px 0
	}
}
  
#modal-register,#modal-validate,#modalDownload,#modalDownloadOk,#fcontact{
	.modal-dialog{
		max-width: 1100px;
	}
	.modal-content{
		width: 1100px;
		max-width: 90vw;
		@include media-breakpoint-down(md) {
			max-width: calc(100vw - 15px);
		}
		.modal-header{
			border-bottom: none;
			.modal-title{
				@extend .fuente-title;
				@include fontSize(27px);
				margin-top: 30px;
				margin-bottom: 0;
				border-bottom: 1px solid $verde;
  				padding-bottom: 15px;
				// @include media-breakpoint-down(md) {
				// 	@include fontSize(30px);
				// }
				span{
				}
			}
		}
		.modal-body{
			padding: 0 60px 60px;
			@include media-breakpoint-down(md) {
				padding: 0 15px 30px;
			}
			h2{
				@include fontSize(16px);
				margin-bottom: 30px;
			}
			#alerta-errores-registro{
				display: none;
			}
			.cuadro-txt-legal{
				border: 2px solid #000;
				padding: 20px;
				margin: 40px 0 20px;
				h3{
					@extend .fuente-title;
					@include fontSize(16px);
					
				}
				p{
					@include fontSize(14px);
					strong{
						@extend .fuente-title;
					}
				}
			}
			h3{
				color:$negro;
			}
		}
	}
}
#modal-validate,#modalDownloadOk{
	.modal-dialog {
		max-width: 745px;
		.modal-content{
			width: unset;
			.modal-body{
				p{
					@include fontSize(18px);
					color:$verde;
					margin-bottom:0px;
				}
				button{
					margin-top: 60px;
				}
			}
		}
	}
}
.modal{
	.modal-content{
		.modal-header{
			.btn-close {
				opacity: 1;
				padding: 0;
				margin: 0;
				top: 20px;
				position: absolute;
				right: 20px;
				width: 20px;
				height: 20px;
				background-size: contain;
				background-image: url('../images/close.svg');
				&:focus{
					outline: none;
				}
			}
		}
		.modal-body{

				a{
					text-decoration: underline;
					color:$negro;
				}

		}

	}

}
.form-group{
	position:relative;
	margin-top: 20px;
	label{
		// color:$negro; // Comentado por Rafa porque parece que sobra
		@extend .fuente-title;
		&:not(.checkbox){
			padding-left: 12px;
		}
		&.css-label{
			@extend .fuente-body;
			a{
				@extend .fuente-title;
			}
		}
	}
	input{
		width: 100%;
		height: 50px;
		border:none;
		background-color: $bg-grey;
		border:solid 1px $bg-grey;
		text-align: left;
		@include border-radius(4px);

	}

	textarea{
		width: 100%;
		height: 38px;
		border:none;
		background-color: $bg-grey;
		border:solid 1px $bg-grey;
		text-align: left;
		height: 215px;
		border-radius: 0;
		resize: none;
	}
	select {
		width: 100%;
		height: 50px;
		border: none;
		background-color: #ebebeb;
		border: 1px solid #ebebeb;
		text-align: left;
		-webkit-appearance: auto;
		-moz-appearance: auto;
		appearance: auto;
	}

	&.has-error{

		input{
			border:solid 1px red;
		}

		textarea{
			border:solid 1px red !important;
		}
	}

}

.tip-text{
	display: none;
	background-color: white;
	border: 1px solid black;
	width: 300px;
	padding: 10px;
	line-height: 1.2em;
	position: absolute;
	top: 30px;
	left: 25px;
	transform: translateX(-100%);
	z-index: 10;
	box-shadow: 0 0 10px;
}
.cajaCheck input[type="checkbox"]:checked,
.cajaCheck input[type="checkbox"]:not(:checked) {
	position: absolute;
	left: -9999px;
	opacity:0;
}

.cajaCheck input[type="checkbox"]:checked + label,
.cajaCheck input[type="checkbox"]:not(:checked) + label
{
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: $negro;
}
.cajaCheck input[type="checkbox"]:checked + label:before,
.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	// border: 2px solid $negro;
	background: $blanco;
	border:1px solid gray;
}
.cajaCheck input[type="checkbox"]:checked + label:after,
.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
	content: '';
	width: 18px;
	height: 18px;
	background: #a98f72;
	position: absolute;
	top: 0px;
	left: 0px;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
.cajaCheck input[type="checkbox"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

body{
	&.mini{
		#creditsuser{
			.header-cont-user{
				top:40px;
				@include media-breakpoint-down(sm) {
					top: 30px;
				}
			}
		}
	}
}
#creditsuser{
	.header-cont-user{
		position: fixed;
		transform: translate(-50% , -50%);
		left: 50%;
		top: 40px;
		z-index: 11;
		pointer-events: none;
		transition: all ease 0.5s;
		@include media-breakpoint-down(md) {
			padding-right: 0px;
		}
		@include media-breakpoint-down(sm) {
			top: 30px;
			padding-right:10px;
		}
		@media (max-width: 400px){
			padding-right: 20px;
		}
		span{
			margin-right: 15px;
			@include fontSize(20px);
			font-family: 'Gotham Medium';
			color: #004ccf;
			@include media-breakpoint-down(sm) {
				margin-right: 10px;
				@include fontSize(16px);
			}
			&:before{
				content:"";
				background-image:url('../images/ico-creditos.svg');
				width:31px;
				height:32px;
				background-size: cover;
				display:inline-block;
				margin-right: 8px;
				@include media-breakpoint-down(sm) {
					width: 21px;
					height: 21px;
					margin-right: 2px;
				}
			}
		}
		a{
			pointer-events: all;
			background: linear-gradient(262.04deg,#e5007d -1.64%,#ff7cc4 158.46%);
			background-color: rgba(0, 0, 0, 0);
			border-radius: 100px;
			line-height: 2.28em;
			text-decoration: none;
			padding: unset;
			height: 25px;
			@include media-breakpoint-down(sm) {
				width: 35px;
				height: 35px;
			}
			div{
				display: flex;
				padding: 0px 5px;
				@include media-breakpoint-down(sm) {
					margin-left: 0px;
					margin-top: 0px;
				}
				b{
					color:#E5007D;
					background-color:$blanco;
					border-radius: 100%;
					width: 15px;
					height: 15px;
					line-height: 1.3em !important;
					margin-right: 5px;
					display:block;
					// margin-top: 15px; Comentado por Rafa, ya tiene la alineación de Bootstrap por lo que no hace falta este margin-top.
					font-size: 12px;
					font-family: 'Gotham Light';
					line-height:1.4em;
					// @media (max-height: 900px) {
					// 	margin-top: 11px;
					// }
					@include media-breakpoint-down(sm) {
						margin-top: 1px;
						margin-right: 0px;
						width: 25px;
						height: 25px;
						font-size: 21px;
					}
				}
				span{
					color: #fff;
					font-size: 12px;
					font-family: Gotham Medium;
					margin-right:5px;
					@include media-breakpoint-down(sm) {
						display:none;
					}
					&:before{
						display:none;
					}
				}
			}
		}
	}
	&.small{
		.header-cont-user{
			top: 25px;
		}
	}
}

#section-title{
	.cont-titular{
		margin-top:80px;
		@include media-breakpoint-down(sm) {
			margin-top: 35px;
		}
		h1{
			@extend .fuente-title;
			@include fontSize(35px);
			margin:0;
		}
		h2{
			@extend .fuente-book;
			@include fontSize(18px);
			line-height: 1.2em;
			color:$txt-grey;
			margin-top:20px;
		}
	}
}

#alert-filtro {
	padding-right: 30px;
	padding-left: 30px;
	.close {
		padding: 5px 15px 4px;
		background-color: transparent;
		border: 0;
	}
}