body.categories{
	#sectionuser{
		.cont-user{
			margin-top:40px;
			border-bottom: 2px solid #def2ff;
			@include media-breakpoint-down(sm) {
				padding-top:20px;
				margin-top:0px;
			}
			// .btn-primary{
			// 	margin-top:0px!important;
			// }
			// .btn-primary{
			// 	margin-bottom:15px;
			// 	width:100%;
			// 	background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
			// 	border-radius:100px;
			// 	color:$blanco;
			// 	width:125px;
			// 	margin-left:auto;
			// 	margin-right:auto;
			// 	display: block;
			// 	@include fontSize(16px);
			// 	font-family: 'Gotham Medium';
			// 	line-height: 2.28em;
			// 	margin-top:40px;
			// 	text-decoration:none;
			// 	@include media-breakpoint-down(sm) {
			// 		@include fontSize(15px);
			// 	}
			// 	@include media-breakpoint-down(sm) {
			// 		@include fontSize(16px!important);
			// 		width:100%;
			// 		margin-top:10px!important;
			// 	}
			// 	@media (max-height: 700px) {
			// 		margin-top:20px;
			// 		height: 35px;
			// 	}
			// 	&.small{
			// 		line-height: 1.7em;
			// 		height: 40px;
			// 		margin-top:0px;
			// 		@media (max-height: 1000px) {
			// 			line-height:2em!important;
			// 		}
			// 		@media (max-height: 900px) {
			// 			line-height:2.6em!important;
			// 		}
			// 		@include media-breakpoint-down(sm) {
			// 			line-height:2.3em!important;
			// 		}
			// 		@media (max-height: 700px) {
			// 			line-height:2.4em;
			// 		}
			// 	}
			// }
			.total_creditos{
				@extend .fuente-book;
				@include fontSize(24px);
				line-height: 1.2em;
				color:$negro;
				margin: 0;
				padding: 0;
				@include media-breakpoint-down(md) {
					@include fontSize(15px);
				}
				@include media-breakpoint-down(sm) {
					@include fontSize(20px);
					padding-bottom:10px;
				}
				span{
					@extend .fuente-title;
				}
			}
		}
	}

	#section_categories{
		flex: 1 1 auto;
		margin-top: 60px;
		margin-bottom: 60px;
		h2{
			@include fontSize(30px);
			@extend .fuente-book;
			margin-top: 60px;	
			margin-bottom: 15px;
			@include media-breakpoint-down(md){
				@include fontSize(25px);
				margin-top: 30px;	
			}
		}
		.item{
			cursor: pointer;
			height:300px;
			position: relative;
			margin-bottom: 15px;
			@include transition(all 0.4s ease);
			transition-delay: 0.2s;
			margin-bottom: 20px;
			@include media-breakpoint-down(md) {
				height:220px;
			}
			// @include media-breakpoint-down(md) {
			// 	height: 63vw;
			// 	width: 48%;
			// }
			.cont-info{
				height:100%;
			}
			.imagen{
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				height: 100%;
				border-radius: 10px;
				@include transition(all 0.5s ease);
				@include media-breakpoint-down(md) {
					// height: calc(63vw - 80px);
					// border-top-left-radius: 10px;
					// border-top-right-radius: 10px;
					// border-bottom-left-radius: 0;
					// border-bottom-right-radius: 0;
					height: 100% !important;
					border-radius: 12px;
				}
			}
			.capa{
				position: absolute;
				left:.25rem;
				bottom: 0;
				width: calc(100% - 0.5rem);
				height: 80px;
				@include transition(height 0.5s ease);
				border-bottom-left-radius:10px;
				border-bottom-right-radius:10px;
				border-top-left-radius:0px;
				border-top-right-radius:0px;
				.icono-check{
					overflow: hidden;
					height: 0px;
					@include transition(height 0.4s ease);
					#svg-check{
						width: 35px;
						height: 35px;
						circle{
							stroke-dasharray: 185;
							stroke-dashoffset: 185;
							@include transition(all 0.4s ease-out);
						}
						polyline{
							stroke-dasharray: 70;
							stroke-dashoffset: 70;
							@include transition(all 0.4s ease-out);
						}
					}
				}
				img{
					position: relative;
					top: 10px;
					@include media-breakpoint-down(sm) {
						height: 47px!important;
						position: absolute;
						top: 20px
					}
					
				}
			}
			&:hover{
				@include media-breakpoint-up(md) {
	
					// filter: brightness(70%);
					.capa{
						height: 100%;
						border-top-left-radius:10px;
						border-top-right-radius:10px;
						@include transition(all 0.4s ease);
						img{
							position: absolute;
							top: 20px
						}
					}
					.text-hover{
						display:block;
						opacity:1;
					}
				}
			}
			&.selected{
				// filter: brightness(33%);
				&:hover{
					// filter: brightness(33%);
				}
				.capa{
					height: 100%;
					position:absolute;
					top:0px;
					border-radius: 10px;
					img{
						position: absolute;
						top: 20px
					}
					.icono-check{
						height:35px;
						bottom: 20px;
						position: relative;
						#svg-check{
							circle{
								transition-delay: 0.3s;
								stroke-dashoffset: 0;
							}
							polyline{
								transition-delay: 0.5s;
								stroke-dashoffset: 0;
							}
						}
					}
				}
				.imagen{
					filter: brightness(53%);
				}
				.text-hover{
					display:block;
					opacity:1;
				}
			}
			&.js-redinactiva{
				transform: scale(0);
				width: 0;
				padding: 0!important;
			}
			.text-hover{
				position: absolute;
				top: 80px;
				z-index:2;
				color:white;
				opacity:0;
				padding-left:15px;
				padding-right:15px;
				@include transition(all 1s ease);
				@include fontSize(14px);
				line-height:1.3em;
			}
		}

	}
}