body.contact{
	@include media-breakpoint-down(md) {
		padding-top: 15px;
	}
	.wrap{
		@include media-breakpoint-down(md) {
			padding-top: 0px;
		}
	}
  .cont-images{
    top:0px!important;
    .img-right{
      position:absolute;
      right:0px;
      top:30px;
      width:33%;
      height:auto;
    }
    .img-top{
      position:absolute;
      width:100%;
      top:30px;
      left:0px;
      height:auto;
      @include media-breakpoint-down(md) {
        top:30px;
      }
    }
    .img-left{
      position:absolute;
      left:0px;
      top:30px;
      width:33%;
      height:auto;
    }
    
  }

  #section-contacta{
    flex: 1 1 auto;
    position: relative;
    padding-top: 10vh;
	@include media-breakpoint-down(md) {
        padding-top: 0;
      }
  }

	.form-contacta{
		flex: 1 1 auto;
		.cajaCheck input[type="checkbox"]:checked,
		.cajaCheck input[type="checkbox"]:not(:checked) {
			position: absolute;
			left: -9999px;
			opacity:0;
		}

		.cajaCheck input[type="checkbox"]:checked + label,
		.cajaCheck input[type="checkbox"]:not(:checked) + label
		{
			position: relative;
			padding-left: 28px;
			cursor: pointer;
			line-height: 20px;
			display: inline-block;
			color: $negro;
		
		}
		.cajaCheck input[type="checkbox"]:checked + label:before,
		.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 16px;
			height: 16px;
			border: 1px solid #C4C4C4!important;
		}
		.cajaCheck input[type="checkbox"]:checked + label:after,
		.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
			content: '';
			width: 18px;
			height: 18px;
			background: $negro;
			position: absolute;
			top: 0px;
			left: 0px;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
			opacity: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
		.cajaCheck input[type="checkbox"]:checked + label:after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		.cajaCheck{
			margin-top:0px;
			label{
				a{
					color:$negro;
				}
			}
		}
		.btn-primary{
			margin-top:30px;
		}
	}
	#txt-contact{
		h1{
			@extend .fuente-title;
			@include fontSize(45px);
			margin-bottom: 30px;
			position: relative;
			padding-left: 30px;
			&:before {
				content: " ";
				background-image: url(../images/stripes.svg);
				background-repeat: no-repeat;
				width: 60px;
				height: 35px;
				position: absolute;
				top: 8px;
				left: 0;
				background-size: contain;
			}
		}
		h2{
			@extend .fuente-title;
			@include fontSize(25px);
			position: relative;
			padding-left: 0;
			margin-top: 40px;
			margin-bottom: 20px;
		}
		p{
			@include fontSize(18px);
			line-height: 1.2em;
		}
		strong, a{
			@extend .fuente-title;
			color: $negro;
			
		}
		ul {
			margin: 0;
			padding: 0;
			padding-left: 0px;
			padding-left: 35px;
			li {
				list-style: none;
				&:before {
					content: "-";
					text-indent: -10px;
					position: absolute;
				}
			}
		}
	}
	.form-group{
		position:relative;
		&.has-error{

			input{
				border:solid 1px red;
			}

			textarea{
				border:solid 1px red !important;
			}
		}
		label{
			font-family:'Gotham Medium';
			@include fontSize(16px);
			padding-left:10px;
		}
		input, textarea{
			border-radius:4px;
			border:1px solid #C4C4C4;
			background-color:$blanco;
			text-align:left;
			width:100%;
			margin-top:5px;
		}
		textarea{
			resize: none;
			height:auto;
		}
		::-webkit-input-placeholder { /* Edge */
			color: #C4C4C4;
		}
		
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #C4C4C4;
		}
		
		::placeholder {
			color: #C4C4C4;
		}
	}
	.contenedor-contacta {
		margin-top: 30px;
		@include media-breakpoint-down(md) {
			margin-top: 30px;
		}
		@include media-breakpoint-down(sm) {
			margin-top:0px;
		}
		h1{
			@include fontSize(20px);
			font-family: 'Gotham Bold';
			color: $negro;
			padding-bottom:10px;
			border-bottom:2px solid #C4C4C4;
			margin-top:15px;
		}
		.texto{
			margin-top:30px;
			@include fontSize(16px);
			font-family: 'Gotham Book';
		}
		ul{
			margin:0px;
			padding:0px;
			padding-left: 35px;

			li{
				list-style: none;

				&:before {
				  content: "-";
				  text-indent: -10px;
				  position:absolute;
				}
				a{
					color:$negro;
					font-family: 'Gotham Bold';
				}
			}
		}
		p,ul{
			color:$negro;
			margin-top:10px!important;
			margin-bottom:0px;
		}
		p{
			padding-left: 15px;
  			padding-right: 15px;
		}
		h2{
			color:$negro;
		}
		label {
			color:$negro;

		}
		.cajaCheck{
			label{
				@include fontSize(10px!important);
				font-family: 'Gotham Book'!important;
				a{
					text-decoration:underline;
					font-family: 'Gotham Book'!important;
				}
			}
		}
		.cuadro-txt-legal {
			h3{
				@include fontSize(16px);
				font-family: 'Gotham Book';
				margin-top:30px;
				color:$negro;
			}
			a{
				font-family: 'Gotham Bold';
				color:$negro;
			}
		}
	}
}
